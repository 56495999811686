import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { Suspense, lazy } from "react";

const About = lazy(() => import("../components/About/index.jsx"));
const BenefitsOfEV = lazy(() => import("../components/Homepage/PluxElectricMedia/BenefitOfEV/index.jsx"));
const Contact = lazy(() => import("../components/Contact/index.jsx"));
const Error = lazy(() => import("../components/Error/Error.jsx"));
const EVLeasingAndRenting = lazy(() => import("../components/Homepage/PluxElectricMedia/EVLeasingAndRenting/index.jsx"));
const FutureOfEV = lazy(() => import("../components/Homepage/PluxElectricMedia/FutureOfEV/index.jsx"));
const Home = lazy(() => import("../components/Homepage/index.jsx"));
const Layout = lazy(() => import("../components/sharedComponent/Layout"));
const PlusXForBusiness = lazy(() => import("../components/PlusxBusiness/index.jsx"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy/index.jsx"));
const Sustainability = lazy(() => import("../components/Sustainability/index.jsx"));
const TermsAndCondition = lazy(() => import("../components/TermsAndCondition/index.jsx"));
const PortablePowerChargingPod = lazy(() => import("../components/FeaturedServices/PortablePowerChargingPod/index.jsx"));
const RiseOfEV = lazy(() => import("../components/Homepage/PluxElectricMedia/RiseOfEV/index.jsx"));
const VeletChargingService = lazy(() => import("../components/FeaturedServices/ValetChargingService/index.jsx"));

const router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <Contact />,
      },
      {
        path: "/plusx-for-business",
        element: <PlusXForBusiness />,
      },
      {
        path: "/sustainability",
        element: <Sustainability />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndCondition />,
      },
      {
        path: "/portable-power-pods",
        element: <PortablePowerChargingPod />,
      },
      {
        path: "/valet-charging-service",
        element: <VeletChargingService />,
      },
      {
        path: "/exploring-the-benefits-of-electric-vehicles-in-the-UAE-a-comprehensive-guide",
        element: <BenefitsOfEV />,
      },
      {
        path: "/the-rise-of-electric-vehicles-in-the-UAE-a-comprehensive-overview",
        element: <RiseOfEV />,
      },
      {
        path: "/electric-vehicle-leasing-and-renting-options-in-the-UAE",
        element: <EVLeasingAndRenting />,
      },
      {
        path: "/the-future-of-electric-mobility-innovations-and-trends-in-the-UAE",
        element: <FutureOfEV />,
      },
      {
        path: "*",
        element: <Error />,
      },
    ],
  },
]);

function Router() {
  return (
    <>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
}

export default Router;
