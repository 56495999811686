import React, { useEffect } from "react";
import Router from "./routes/Router";

import AOS from "aos";
import { HelmetProvider } from "react-helmet-async";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in",
      // once: true,
    });
  }, []);
  return (
    <>
      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </>
  );
}

export default App;
